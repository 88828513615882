<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row class="match-height">
        <b-col>
          <b-card>
            <h3 class="d-flex justify-content-center align-items-center mb-2">
              {{ $route.name != 'add-store-suppliers' ? `Proveedor ${currentSupplier.name}` : 'Añade proveedores a la tienda' }}
            </h3>
            <validation-observer
              #default="{ handleSubmit }"
              ref="supplierFormObserver"
            >
              <h4
                v-if="$route.name != 'add-store-suppliers'"
                class="mb-2"
              >
                Información adicional del proveedor
              </h4>
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-form-group
                  v-if="$route.name === 'add-store-suppliers'"
                  label="Seleccione los proveedores que desea añadir"
                  class="mb-1"
                  label-for="stores"
                >

                  <v-select
                    id="stores"
                    v-model="establishment_suppliers_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="supplier_attributes"
                    :options="suppliers"
                    :reduce="option => option.id"
                  >
                    <template #selected-option="{ supplier_attributes }">
                      <span> {{ supplier_attributes.name }}</span>
                    </template>

                    <template #option="{ supplier_attributes }">
                      <span> {{ supplier_attributes.name }}</span>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- CURRENT LOAN BALANCE -->
                <validation-provider
                  v-if="$route.name === 'edit-store-suppliers'"
                  #default="validationContext"
                  name="Loan Balance"
                  rules="required"
                >
                  <b-form-group
                    label="Saldo deudor"
                    label-for="loanBalance"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="fas fa-dollar-sign" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="loanBalance"
                        v-model="store_supplier.current_loan_balance"
                        trim
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <div v-if="$route.name === 'store-supplier-payment'">
                  <validation-provider
                    #default="validationContext"
                    name="amount"
                    rules="required"
                  >
                    <b-form-group
                      label="Monto a pagar"
                      label-for="amount"
                    >
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <i class="fas fa-dollar-sign" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="amount"
                          v-model="supplier_payment.amount"
                          trim
                        />
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="validationContext"
                    name="payment_method"
                    rules="required"
                  >
                    <b-form-group
                      label="Método de pago"
                      label-for="payment_method"
                    >
                      <b-input-group>
                        <b-form-select
                          v-model="supplier_payment.payment_method"
                          :options="paymentMethodsOptions"
                        />
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="validationContext"
                    name="payment_reason"
                  >
                    <b-form-group
                      label="Razón de pago"
                      label-for="payment_reason"
                    >
                      <b-input-group>
                        <b-form-textarea
                          id="payment_reason"
                          v-model="supplier_payment.payment_reason"
                          placeholder="Ingresa el motivo de pago..."
                          rows="3"
                          max-rows="6"
                        />
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $route.name === 'store-supplier-payment' ? 'Pagar' : 'Guardar' }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :to="{ name: 'store-suppliers', params: { id: $route.params.store_id } }"
                  >
                    Cancelar
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroupPrepend, BInputGroup, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormSelect,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      establishment_suppliers_ids: null,
      establishmentStores: null,
      store_supplier: {
        current_loan_balance: null,
      },
      supplier_payment: {
        payment_type: 'walleat',
        payment_reason: null,
        payment_method: null,
        amount: null,
      },
      paymentMethodsOptions: [
        { value: 'cash', text: 'Efectivo de la caja' },
        { value: 'cashNotInCashRegister', text: 'Efectivo no registrado en caja' },
        { value: 'onlinePayment', text: 'Tranferencia bancaria' },
        { value: 'bankCard', text: 'Pago con tarjeta' },
        { value: 'codi', text: 'Codi' },
      ],
    }
  },
  computed: {
    ...mapGetters('suppliers', ['currentSupplier', 'currentEstablishmentSupplier', 'suppliers']),
  },
  beforeMount() {
    if (this.$route.name !== 'add-store-suppliers') {
      this.fetchStoreSupplier(this.$route.params.store_supplier_id)
        .then(response => {
          this.store_supplier = response
        })
    } else if (this.$route.name === 'add-store-suppliers') {
      this.fetchEstablishmentSuppliers({
        by_establishment: this.$route.params.establishment_id,
        by_active_status: 1,
        meta: { pagination: { per_page: 1000 } },
      })
    }
  },
  methods: {
    ...mapActions('suppliers', [
      'fetchStoreSupplier',
      'addStoreSupplier',
      'editStoreSupplier',
      'addSupplierPayment',
      'fetchEstablishmentSuppliers',
    ]),
    onSubmit() {
      this.$refs.supplierFormObserver.validate().then(success => {
        if (success) {
          if (this.$route.name === 'edit-store-suppliers') {
            this.editStoreSupplier({ store_supplier: this.store_supplier })
              .then(() => this.$router.push({ name: 'store-suppliers', params: { id: this.$route.params.store_id } }))
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.data.messages.store_supplier[0],
                  },
                })
              })
          } else if (this.$route.name === 'add-store-suppliers') {
            this.establishment_suppliers_ids.forEach(supplier => {
              this.addStoreSupplier({ establishment_supplier_id: supplier, store_id: this.$route.params.store_id })
                .then(() => this.$router.push({ name: 'store-suppliers', params: { id: this.$route.params.store_id } }))
                .catch(error => {
                  if (error.response.data.messages.establishment_supplier_id) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: error.response.data.messages.establishment_supplier_id[0],
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: error.response.data.messages.store_supplier[0],
                      },
                    })
                  }
                })
            })
          } else {
            this.supplier_payment.store_supplier_id = this.store_supplier.id
            this.addSupplierPayment(this.supplier_payment)
              .then(() => this.$router.push({ name: 'store-suppliers', params: { id: this.$route.params.store_id } }))
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.data.messages.base[0],
                  },
                })
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
